// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homePageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
}

.homePageMainLine {
    text-decoration: underline;
    color: green;
}

.headerText {
    width: 50%;
    background-color: gainsboro;
    padding: 3px;
    
}

.refundsImg {
    width: 50%;
    height: auto;
    float: left;
}

#information {
    align-items: center;
}

ul li {
    list-style-position:inside;
}

h2 {
    color: blue;
    text-decoration: underline;
}

@media (max-width: 768px) {

    .homePageHeader {
        display: flex;
        flex-direction: column;
        width: 99%;
    }

    .headerText {
        width: 99%;
    }

    .refundsImg {
        height: 200px;
        width: 100%;
    }

    .headerText h1 {
        font-size: 1.5em;
    }

    .headerText h2 {
        font-size: 1.2em;
    }

    .headerText h4 {
        font-size: 1em;
    }

    .headerText li {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .headerText h1 {
        font-size: 1.2em;
    }

    .headerText h2 {
        font-size: 1em;
    }

    .headerText h4 {
        font-size: 0.9em;
    }

    .headerText li {
        font-size: 0.8em;
    }
}

 


`, "",{"version":3,"sources":["webpack://./src/components/HomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,2BAA2B;IAC3B,YAAY;;AAEhB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,0BAA0B;AAC9B;;AAEA;;IAEI;QACI,aAAa;QACb,sBAAsB;QACtB,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,aAAa;QACb,WAAW;IACf;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".homePageHeader {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    height: auto;\n}\n\n.homePageMainLine {\n    text-decoration: underline;\n    color: green;\n}\n\n.headerText {\n    width: 50%;\n    background-color: gainsboro;\n    padding: 3px;\n    \n}\n\n.refundsImg {\n    width: 50%;\n    height: auto;\n    float: left;\n}\n\n#information {\n    align-items: center;\n}\n\nul li {\n    list-style-position:inside;\n}\n\nh2 {\n    color: blue;\n    text-decoration: underline;\n}\n\n@media (max-width: 768px) {\n\n    .homePageHeader {\n        display: flex;\n        flex-direction: column;\n        width: 99%;\n    }\n\n    .headerText {\n        width: 99%;\n    }\n\n    .refundsImg {\n        height: 200px;\n        width: 100%;\n    }\n\n    .headerText h1 {\n        font-size: 1.5em;\n    }\n\n    .headerText h2 {\n        font-size: 1.2em;\n    }\n\n    .headerText h4 {\n        font-size: 1em;\n    }\n\n    .headerText li {\n        font-size: 0.9em;\n    }\n}\n\n@media (max-width: 480px) {\n    .headerText h1 {\n        font-size: 1.2em;\n    }\n\n    .headerText h2 {\n        font-size: 1em;\n    }\n\n    .headerText h4 {\n        font-size: 0.9em;\n    }\n\n    .headerText li {\n        font-size: 0.8em;\n    }\n}\n\n \n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
