import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <h1>מדיניות פרטיות ותנאי שימוש</h1>

            <h2>מדיניות פרטיות</h2>

            <h3>1. איסוף מידע</h3>
            <p>אנו עשויים לאסוף מידע אישי מזהה ממך כאשר אתה ממלא טופס באתר שלנו. ייתכן שתידרש להזין את שמך, כתובת הדוא"ל ומספר הטלפון שלך.</p>

            <h3>2. שימוש במידע שנאסף</h3>
            <p>המידע שאנו אוספים משמש רק למטרות הבאות:
                <ul>
                    <li>לשליחת פרטי הקשר שלך לנותן השירות.</li>
                    <li>למענה לבקשות שלך ולמתן שירות הלקוחות הנדרש.</li>
                </ul>
            </p>

            <h3>3. הגנה על המידע שלך</h3>
            <p>אנו לא שומרים את המידע האישי שלך באתר שלנו או במסד נתונים. המידע נשלח ישירות לנותן השירות באמצעות מייל ונמחק מיידית מהמערכת שלנו.</p>

            <h3>4. שיתוף מידע אישי</h3>
            <p>אנו לא משתפים, מוכרים, סוחרים, או משכירים מידע אישי מזהה של משתמשים לאחרים. המידע נשלח רק לנותן השירות לצורך מענה לבקשתך.</p>

            <h3>5. שינויים במדיניות הפרטיות</h3>
            <p>אנו שומרים לעצמנו את הזכות לעדכן מדיניות פרטיות זו בכל עת. כאשר נעשה זאת, נפרסם הודעה מעודכנת בעמוד הראשי של האתר שלנו. אנו ממליצים למשתמשים לבדוק לעיתים קרובות את העמוד הזה כדי להתעדכן לגבי שינויים כלשהם.</p>

            <h2>תנאי שימוש</h2>

            <h3>1. קבלת התנאים</h3>
            <p>באמצעות גישה לאתר זה ושימוש בו, אתה מאשר שקראת, הבנת והסכמת להיות כפוף לתנאים ולהגבלות אלו.</p>

            <h3>2. שימוש באתר</h3>
            <p>אתה מסכים להשתמש באתר זה אך ורק למטרות חוקיות ובהתאם לכל החוקים המקומיים, המדינתיים והבינלאומיים החלים.</p>

            <h3>3. זכויות קניין רוחני</h3>
            <p>כל התוכן המוצג באתר זה, לרבות אך לא מוגבל לטקסט, גרפיקה, לוגואים, סמלים ותמונות, הוא רכושנו או רכוש השותפים שלנו ומוגן בזכויות יוצרים וחוקים אחרים להגנת הקניין הרוחני.</p>

            <h3>4. אחריות מוגבלת</h3>
            <p>אנו לא נהיה אחראים לכל נזק ישיר, עקיף, מקרי, תוצאתי או מיוחד שנובע משימושך באתר זה או מחוסר יכולתך להשתמש בו.</p>

            <h3>5. שינויים בתנאי השימוש</h3>
            <p>אנו שומרים לעצמנו את הזכות לשנות תנאי שימוש אלו בכל עת. אנו נודיע על שינויים מהותיים באמצעות פרסום התנאים החדשים באתר. המשך השימוש באתר לאחר ביצוע השינויים מהווה הסכמה לתנאים החדשים.</p>

            <h3>יצירת קשר</h3>
            <p>אם יש לך שאלות בנוגע למדיניות פרטיות זו או לתנאי השימוש, אנא צור קשר דרך:
                <ul>
                    <li>אימייל: avivtaxes@gmail.com</li>
                    <li>טלפון: 050-406-6900</li>
                </ul>
            </p>
        </div>
    );
}

export default PrivacyPolicy;
