// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #333; 
    color: #fff; 
    padding: 20px 0;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.footerSection {
    flex: 1 1;
    min-width: 200px;
    margin: 10px 0;
}

.footerSection h4 {
    font-size: 18px;
    margin-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 2px solid gold; 
    display: inline-block;
    padding-bottom: 5px;
}

.footerSection p,
.footerSection a,
.footerSection ul,
.footerSection li {
    margin: 5px 0;
    font-size: 14px;
    color: #ccc; 
    text-decoration: none;
}

.footerSection a {
    margin: 5px;
}

.footerSection a:hover {
    color: gold; 
}

.footerSection ul {
    list-style-type: none;
    padding: 0;
}

.footerSection ul li a {
    display: block;
}

.footerSection ul li a:hover {
    color: gold; 
}

.footerSection p:last-child {
    margin-top: 20px;
    font-size: 15px;
    color: white; 
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,6BAA6B;IAC7B,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;;;;IAII,aAAa;IACb,eAAe;IACf,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["footer {\n    background-color: #333; \n    color: #fff; \n    padding: 20px 0;\n    text-align: center;\n    font-family: 'Arial', sans-serif;\n}\n\n.footer-container {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap;\n    max-width: 1200px;\n    margin: 0 auto;\n}\n\n.footerSection {\n    flex: 1;\n    min-width: 200px;\n    margin: 10px 0;\n}\n\n.footerSection h4 {\n    font-size: 18px;\n    margin-bottom: 10px;\n    text-transform: uppercase;\n    border-bottom: 2px solid gold; \n    display: inline-block;\n    padding-bottom: 5px;\n}\n\n.footerSection p,\n.footerSection a,\n.footerSection ul,\n.footerSection li {\n    margin: 5px 0;\n    font-size: 14px;\n    color: #ccc; \n    text-decoration: none;\n}\n\n.footerSection a {\n    margin: 5px;\n}\n\n.footerSection a:hover {\n    color: gold; \n}\n\n.footerSection ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.footerSection ul li a {\n    display: block;\n}\n\n.footerSection ul li a:hover {\n    color: gold; \n}\n\n.footerSection p:last-child {\n    margin-top: 20px;\n    font-size: 15px;\n    color: white; \n}\n\n@media (max-width: 768px) {\n    .footer-container {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
