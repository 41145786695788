// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    direction: rtl; /* Right-to-left text direction */
    text-align: right; /* Right align the text */
}

.privacy-policy h1, .privacy-policy h2, .privacy-policy h3 {
    color: #333;
}

.privacy-policy p, .privacy-policy ul {
    color: #555;
    line-height: 1.6;
}

.privacy-policy ul {
    padding-left: 20px;
}

.privacy-policy li {
    margin-bottom: 10px;
}

/* התאמת העיצוב למסכים קטנים יותר */
@media (max-width: 768px) {
    .privacy-policy {
        margin: 10px;
        padding: 10px;
    }

    .privacy-policy h1 {
        font-size: 1.5em;
    }

    .privacy-policy h2 {
        font-size: 1.3em;
    }

    .privacy-policy h3 {
        font-size: 1.1em;
    }

    .privacy-policy p, .privacy-policy ul {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .privacy-policy h1 {
        font-size: 1.2em;
    }

    .privacy-policy h2 {
        font-size: 1.1em;
    }

    .privacy-policy h3 {
        font-size: 1em;
    }

    .privacy-policy p, .privacy-policy ul {
        font-size: 0.9em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,8BAA8B;IAC9B,cAAc,EAAE,iCAAiC;IACjD,iBAAiB,EAAE,yBAAyB;AAChD;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA,mCAAmC;AACnC;IACI;QACI,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".privacy-policy {\n    margin: 20px;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    font-family: Arial, sans-serif;\n    direction: rtl; /* Right-to-left text direction */\n    text-align: right; /* Right align the text */\n}\n\n.privacy-policy h1, .privacy-policy h2, .privacy-policy h3 {\n    color: #333;\n}\n\n.privacy-policy p, .privacy-policy ul {\n    color: #555;\n    line-height: 1.6;\n}\n\n.privacy-policy ul {\n    padding-left: 20px;\n}\n\n.privacy-policy li {\n    margin-bottom: 10px;\n}\n\n/* התאמת העיצוב למסכים קטנים יותר */\n@media (max-width: 768px) {\n    .privacy-policy {\n        margin: 10px;\n        padding: 10px;\n    }\n\n    .privacy-policy h1 {\n        font-size: 1.5em;\n    }\n\n    .privacy-policy h2 {\n        font-size: 1.3em;\n    }\n\n    .privacy-policy h3 {\n        font-size: 1.1em;\n    }\n\n    .privacy-policy p, .privacy-policy ul {\n        font-size: 1em;\n    }\n}\n\n@media (max-width: 480px) {\n    .privacy-policy h1 {\n        font-size: 1.2em;\n    }\n\n    .privacy-policy h2 {\n        font-size: 1.1em;\n    }\n\n    .privacy-policy h3 {\n        font-size: 1em;\n    }\n\n    .privacy-policy p, .privacy-policy ul {\n        font-size: 0.9em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
