import React from "react";
import "./EducationFundsInfo.css";

function EducationFundInfo() {
    
    return(
        <div>
            <div className="educationFundsHeader">
                <img src="/images/eduction-refund-concept.jpg" alt="education funds" title="Image by freepik" className="educationFundsHeaderImage" rel="preload" loading="lazy"/>
                <div className="infoText">
                    <h1>קרן השתלמות.</h1>
                    <h4>קרן השתלמות היא תוכנית חיסכון לטווח בינוני, שמטרתה לעודד עובדים לחסוך לטובת השתלמות מקצועית. הקרן מאפשרת ליהנות מהטבות מס ייחודיות ומהכנסות פטורות ממס במידה ועומדים בתנאים הנדרשים.</h4>
                    <h3>מהי קרן השתלמות?</h3>
                    <h4>קרן השתלמות היא כלי חיסכון המיועד לעובדים שכירים ועצמאים כאחד. היא נוסדה במטרה לעודד חסכון לטווח בינוני עבור השתלמות מקצועית, אך בפועל היא משמשת ככלי חסכון כללי.</h4>
                    <h2>יתרונות קרן ההשתלמות:</h2>
                    <h4>הטבות מס: הפקדות המעסיק לקרן השתלמות פטורות ממס עד לתקרת הפקדה מסוימת.</h4>
                    <h4>הכנסות פטורות ממס: הרווחים בקרן פטורים ממס רווח הון אם הכספים נשארים בקרן לפחות 6 שנים (או 3 שנים אם מדובר בכספים שהוצאו לצורכי השתלמות).</h4>
                    <h4>נזילות: לאחר 6 שנים, הכספים בקרן ההשתלמות ניתנים למשיכה <b>ללא תשלום מס.</b></h4>
                    <h4>שכירים: המעסיק והעובד מפרישים יחד לקרן השתלמות, כאשר חלקו של המעסיק פטור ממס.</h4>
                    <h4>עצמאים: יכולים להפקיד לקרן השתלמות עד לתקרה מסוימת ולקבל הטבות מס על ההפקדות.</h4>
                    <h2>אם ביצעתם משיכות מקרן השתלמות לפני המועד בין השנים 2018-2023, פנו אלינו לייעוץ מקצועי ולבדוק את זכאותכם להחזרי מס. ייתכן שמגיע לכם סכומים נכבדים שיכולים לשפר את מצבכם הכלכלי.</h2>
                </div>
            </div>
        </div>

    )
}

export default EducationFundInfo;