import React from "react";
import './HomePage.css';

function HomePage() {

    return(
        <div>
            <div className="homePageHeader">
                <img src="/images/refunds img.jpg" alt="refundsImage" className="refundsImg" title="image" rel="preload" loading="lazy"/>
                <div className="headerText" dir="rtl">
                    <h1 className="homePageMainLine">אביב יצחקי - מומחה להחזרי מס לשכירים.</h1>
                    <h4>"נעים מאוד, אני אביב יצחקי,  בעל תואר ראשון בכלכלה ומנהל עסקים ועבר תעסוקתי במס הכנסה. ואני כאן כדי לעזור לכם לקבל את מה שמגיע לכם! עם ניסיון ומומחיות בתחום החזרי מס לשכירים  וידע מקצועי ממס הכנסה שרכשתי בתקופת עבודתי שם, אני מבטיח ללוות אתכם בתהליך החזר המס בצורה מקצועית, אישית ויעילה. אל תוותרו על הכסף שלכם – פנו אליי עוד היום, ואני אדאג שתראו את החזר המס שאתם זכאים לו!"</h4>
                    <h2>יושרה ושקיפות:</h2>
                    <h4>הצגת תהליך החזר המס באופן ברור ומפורט.</h4>
                    <h4>דיווח על כל העלויות מראש ללא עמלות נסתרות.</h4>
                    <h2>ניסיון ומומחיות:</h2>
                    <h4>ניסיון מעשי בטיפול במאות או אלפי החזרי מס לשכירים.</h4>
                    <h4>יכולת לזהות את כל ההטבות והזיכויים שמגיעים ללקוח.</h4>
                    <h2>יחס אישי ושירות לקוחות מעולה:</h2>
                    <h4>יחס אישי לכל לקוח תוך הבנת צרכיו הספציפיים.</h4>
                    <h4>זמינות למתן תשובות מהירות ומקצועיות לשאלות הלקוח.</h4>
                    <h4>בחירה באדם אמין וישר לביצוע החזרי מס לשכירים היא קריטית להצלחה בתהליך. יועץ מס מקצועי יבטיח שתקבלו את ההחזר שמגיע לכם במינימום מאמץ ובמקסימום שקיפות ואמינות.</h4>
                    <h4>אני כאן כדי לענות על כל שאלה שיש לכם.</h4>
                </div>
            </div>
        </div>

    );
}

export default HomePage;