// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informationGeneralHeader {
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: lightgray;
}

.informationGeneralImage {
    height: auto;
    width: 50%;
    float: left;
}

.textInfo {
    direction: rtl;
}

@media (max-width: 768px) {

    .informationGeneralHeader {
        flex-direction:column;
    }

    .informationGeneralImage {
        height: auto;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/InformationGeneral.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI;QACI,qBAAqB;IACzB;;IAEA;QACI,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".informationGeneralHeader {\n    display: flex;\n    justify-content: space-between;\n    text-align: center;\n    background-color: lightgray;\n}\n\n.informationGeneralImage {\n    height: auto;\n    width: 50%;\n    float: left;\n}\n\n.textInfo {\n    direction: rtl;\n}\n\n@media (max-width: 768px) {\n\n    .informationGeneralHeader {\n        flex-direction:column;\n    }\n\n    .informationGeneralImage {\n        height: auto;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
